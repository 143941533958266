import { User } from './user.class'
import { Authn } from './authn.class'

export class Operator {
  _id: string
  user: User
  username: string
  locale: string
  creationDate: Date
  updateDate: Date

  constructor (obj: Object) {
    const properties = ['_id', 'username', 'locale']

    for (const property in obj) {
      if (properties.includes(property)) {
        this[property] = obj[property]
      }
    }

    if (obj['user']) {
      this.user = (obj['user'] instanceof User)
        ? obj['user']
        : new User(obj['user'])
    }

    if (obj['creationDate']) {
      this.creationDate = new Date(obj['creationDate'])
    }

    if (obj['updateDate']) {
      this.updateDate = new Date(obj['updateDate'])
    }
  }

  get authn (): Authn {
    return this.user && this.user.authn
  }

  get email (): string {
    return this.authn && this.authn.email
  }

  get authn_id (): string {
    return this.user && this.user.authn._id
  }

  toCreateDTO (): any {
    return {
      email: this.email,
      firstname: this.user && this.user.firstname,
      lastname: this.user && this.user.lastname,
      username: this.username,
      newsletter: this.user && this.user.newsletter,
      genre: this.user && this.user.genre,
      locale: this.locale,
      roles: this.authn && this.authn.roles &&
        this.authn.roles.map(({ _id }) => _id)
    }
  }

  toUpdateDTO (): any {
    return {
      email: this.email,
      firstname: this.user && this.user.firstname,
      lastname: this.user && this.user.lastname,
      username: this.username,
      newsletter: this.user && this.user.newsletter,
      genre: this.user && this.user.genre,
      locale: this.locale,
      roles: this.authn && this.authn.roles &&
        this.authn.roles.map(({ _id }) => _id)
    }
  }
}
