import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, BehaviorSubject } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from '../../environments/environment'
import { Response } from '../classes/response.class'
import { Operator } from '../classes/operator.class'
import { Contact } from '../classes/contact.class'

@Injectable({
  providedIn: 'root'
})

export class OperatorService {
  private operatorSource = new BehaviorSubject<Operator | null>(null)
  currentOperator = this.operatorSource.asObservable()
  private archUrl = `${environment.baseUrl}${environment.archContext.authn}`
  private accountUrl = `${environment.baseUrl}${environment.accountContext.account}`

  constructor (private http: HttpClient) { }

  public getOperatorInfo (): Observable<Response<Operator>> {
    return this.http
      .get<Response<Operator>>(`${this.accountUrl}/operator`)
      .pipe(map(({ message, data }) =>
        new Response<Operator>(message, new Operator(data))
      ))
  }

  public setOperatorInfo (operator): Observable<Response<Operator>> {
    return this.http
      .patch<Response<Operator>>(`${this.accountUrl}/operator`, operator)
      .pipe(map(({ message, data }) =>
        new Response<Operator>(message, new Operator(data))
      ))
  }

  public getOperators (): Observable<Response<Operator[]>> {
    return this.http
      .get<Response<Operator[]>>(`${this.accountUrl}/operator/all`)
      .pipe(map(({ message, data }) =>
        new Response<Operator[]>(
          message,
          data && data.map((operator) => new Operator(operator))
        )
      ))
  }

  public createOperator (
    operator: Operator,
    password: string
  ): Observable<Response<Operator>> {
    const payload = {
      ...operator.toCreateDTO(),
      password
    }

    return this.http
      .post<Response<Operator>>(`${this.archUrl}/operator`, payload)
      .pipe(map(({ message, data }) =>
        new Response<Operator>(message, new Operator(data))
      ))
  }

  public getOperator (authn: string): Observable<Response<Operator>> {
    return this.http
      .get<Response<Operator>>(`${this.accountUrl}/operator/${authn}`)
      .pipe(map(({ message, data }) =>
        new Response<Operator>(message, new Operator(data))
      ))
  }

  public getProfile (): Observable<Response<Operator>> {
    return this.http
      .get<Response<Operator>>(`${this.accountUrl}/operator/`)
      .pipe(map(({ message, data }) =>
        new Response<Operator>(message, new Operator(data))
      ))
  }

  public updateOperator (
    operator: Operator
  ): Observable<Response<Operator>> {
    const payload = operator.toUpdateDTO()

    return this.http
      .patch<Response<Operator>>(`${this.archUrl}/operator`, payload)
      .pipe(map(({ message, data }) =>
        new Response<Operator>(message, new Operator(data))
      ))
  }

  public updateProfile (form: Operator): Observable<Response<Operator>> {
    const query = {
      ...form,
      ...form.user,
      user: undefined,
      email: form.user.authn.email,
      authn: form.user.authn._id,
      locale: form.locale
    }

    return this.http
      .patch<Response<Operator>>(`${this.accountUrl}/operator`, query)
      .pipe(map(({ message, data }) =>
        new Response<Operator>(message, new Operator(data))
      ))
  }

  public getContacts (): Observable<Response<Contact[]>> {
    return this.http
      .get<Response<Contact[]>>(`${this.accountUrl}/operator/contacts`)
      .pipe(map(({ message, data }) =>
        new Response<Contact[]>(
          message,
          data && data.map((contact) => new Contact(contact))
        )
      ))
  }

  public searchOperator (form: Object): Observable<Response<Operator[]>> {
    return this.http
      .post<Response<Operator[]>>(`${this.accountUrl}/operator/search`, form)
      .pipe(map(({ message, data }) =>
        new Response<Operator[]>(
          message,
          data && data.map((operator) => new Operator(operator))
        )
      ))
  }

  public deleteOperator (authn: string): Observable<Response<Operator>> {
    return this.http
      .delete<Response<Operator>>(`${this.archUrl}/operator/${authn}`)
      .pipe(map(({ message, data }) =>
        new Response<Operator>(message, new Operator(data))
      ))
  }

  public upgradeUserToOperator (
    form: Operator
  ): Observable<Response<Operator>> {
    return this.http
      .post<Response<Operator>>(`${this.accountUrl}/operator/upgrade`, {
        authn: form.user.authn._id,
        username: form.username,
        birthday: form.user.birthday.toISOString(),
        genre: form.user.genre
      })
      .pipe(map(({ message, data }) => data
        ? new Response<Operator>(message, new Operator(data))
        : new Response<Operator>(message, null)
      ))
  }

  changeOperatorInfo (operator: Operator) {
    this.operatorSource.next(operator)
  }
}
