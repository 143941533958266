import { Component, ViewChild } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { MatDialog, MatDialogConfig, MatPaginator, MatTableDataSource } from '@angular/material'
import { TranslateService } from '@ngx-translate/core'
import { VendorService } from '../../../services/vendor.service'
import { Vendor } from '../../../classes/vendor.class'
import { Modal } from 'src/app/classes/modal.class'
import { Alert } from 'src/app/classes/alert.class'
import { DateFormat } from 'src/app/enums/date-format.enum'
import { AlertType } from 'src/app/enums/alert-type.enum'
import { CreateVendorComponent } from '../create-vendor/create-vendor.component'
import { filter, switchMap } from 'rxjs/operators'

@Component({
  selector: 'app-search-vendor',
  templateUrl: './search-vendor.component.html',
  styleUrls: ['./search-vendor.component.scss']
})
export class SearchVendorComponent {
  public displayedColumns: string[]
  public dataSource: MatTableDataSource<Vendor>
  public loading = false
  public modal: Modal
  public alert: Alert
  public searchVendorForm: FormGroup = new FormGroup({
    name: new FormControl(''),
    description: new FormControl(''),
    active: new FormControl()
  })

  @ViewChild(MatPaginator) paginator: MatPaginator

  public DateFormat = DateFormat

  constructor (
    private _dialog: MatDialog,
    private _translate: TranslateService,
    private _vendor: VendorService
  ) { }

  public showModal (el: Vendor): void {
    this.modal = new Modal({
      title: this._translate.instant('MODAL.TITLE.DELETE_VENDOR'),
      body: this._translate.instant('MODAL.BODY.DELETE_VENDOR') + ' ' + el.name + '?',
      buttons: [
        {
          title: this._translate.instant('BUTTON.DELETE')
        }
      ],
      data: el._id
    })

    this.modal.confirm()
  }

  public applyFilter (filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase()
  }

  public async create () {
    try {
      const dialogRef = this._dialog.open(CreateVendorComponent, {
        panelClass: 'maxh-100-vh'
      })
      dialogRef.afterClosed()
        .pipe(
          filter((data) => !!data),
          switchMap((data) => this._vendor.createVendor(new Vendor(data)))
        )
        .subscribe(vendorResponse => {
          if (vendorResponse.invalid) {
            this.alert = new Alert({
              type: AlertType.DANGER,
              message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${vendorResponse.message}`
            })
            this.alert.present()
          }

          this.search()
        }, (error) => {
          this.alert = new Alert({
            type: AlertType.DANGER,
            message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
          })
          this.alert.present()
        })
    } catch (error) {
      this.alert = new Alert({
        type: AlertType.DANGER,
        message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
      })
      this.alert.present()
    }
  }

  public async update (vendor: Vendor) {
    try {
      const dialogConfig = new MatDialogConfig()
      dialogConfig.data = vendor
      dialogConfig.panelClass = 'maxh-100-vh'
      const dialogRef = this._dialog.open(CreateVendorComponent, dialogConfig)
      dialogRef.afterClosed()
        .pipe(
          filter((data) => !!data),
          switchMap((data) => this._vendor.updateVendor(new Vendor(data)))
        )
        .subscribe(vendorResponse => {
          if (vendorResponse.invalid) {
            this.alert = new Alert({
              type: AlertType.DANGER,
              message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${vendorResponse.message}`
            })
            this.alert.present()
          }

          this.search()
        }, (error) => {
          this.alert = new Alert({
            type: AlertType.DANGER,
            message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
          })
          this.alert.present()
        })
    } catch (error) {
      this.alert = new Alert({
        type: AlertType.DANGER,
        message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
      })
      this.alert.present()
    }
  }

  public async delete (_id: string) {
    try {
      const result = await this._vendor.deleteVendor(_id).toPromise()
      if (result.invalid) {
        this.alert = new Alert({
          type: AlertType.DANGER,
          message: `${this._translate.instant('ALERT.MESSAGE.INVALID')} ${result.message}`
        })
        this.alert.present()
        return
      }
      this.dataSource.data = this.dataSource.data.filter(d => d._id !== _id)
      this.alert = new Alert({
        type: AlertType.SUCCESS,
        message: this._translate.instant('ALERT.MESSAGE.VENDOR_DELETED')
      })
      this.alert.present()
    } catch (error) {
      this.alert = new Alert({
        type: AlertType.DANGER,
        message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
      })
      this.alert.present()
    }
  }

  public async search () {
    this.loading = true
    try {
      const result = await this._vendor
        .searchVendor(this.searchVendorForm.value).toPromise()

      if (result.invalid) {
        this.alert = new Alert({
          type: AlertType.DANGER,
          message: `${this._translate.instant('ALERT.MESSAGE.INVALID')} ${result.message}`
        })
        this.alert.present()
        this.loading = false
        return
      }
      this.displayedColumns = [
        'NAME',
        'ACTIVE',
        'DESCRIPTION',
        'CREATIONDATE',
        'UPDATEDATE',
        'BUTTONS'
      ]
      this.dataSource = new MatTableDataSource(result.data)
      this.dataSource.paginator = this.paginator
      this.loading = false
    } catch (error) {
      this.alert = new Alert({
        type: AlertType.DANGER,
        message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
      })
      this.alert.present()
      this.loading = false
    }
  }
}
