import { Component, OnInit, ViewChild } from '@angular/core'
import { FormGroup, FormControl } from '@angular/forms'
import { OperatorService } from '../../services/operator.service'
import { TranslateService } from '@ngx-translate/core'
import { Alert } from 'src/app/classes/alert.class'
import { AlertType } from 'src/app/enums/alert-type.enum'
import { Operator } from 'src/app/classes/operator.class'
import { FormCanDeactivate } from '../../guards/leave-page/form-can-deactivate'
import { ModalComponent } from 'src/app/components/modal/modal.component'

@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.scss']
})
export class PreferencesComponent extends FormCanDeactivate implements OnInit {
  @ViewChild(ModalComponent) leaveModal: ModalComponent

  public preferencesForm: FormGroup = new FormGroup({
    locale: new FormControl('en'),
    active: new FormControl()
  })
  public alert: Alert
  public loading = false

  constructor (
    private _translate: TranslateService,
    private _operator: OperatorService,
  ) { super() }

  async ngOnInit () {
    try {
      const response = await this._operator.getOperatorInfo().toPromise()

      if (response.invalid) {
        return this.openErrorAlert(
          `${this._translate.instant('ALERT.MESSAGE.INVALID')}`
          + ` ${this._translate.instant(response.message)}`
          )
      }
      this.preferencesForm.patchValue({ locale: response.data.locale })
    } catch (error) {
      this.openErrorAlert(
        `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${this._translate.instant(error.error.message)}`)
    }
  }

  async submit (): Promise<void> {
    try {
      this.loading = true
      const response = await this._operator.getOperatorInfo().toPromise()
      if (response.invalid) {
        return this.openErrorAlert(
        `${this._translate.instant('ALERT.MESSAGE.INVALID')}`
        + ` ${this._translate.instant(response.message)}`)
      }

      const value = response.data
      const operator = new Operator({
        user: value.user,
        username: value.username
      })
      // unnecessary information
      operator.user.authn.roles = undefined
      operator.user.locale = undefined

      operator.locale = this.preferencesForm.value.locale
      const response2 = await this._operator
        .updateProfile(operator).toPromise()

      if (response2.invalid) {
        return this.openErrorAlert(
          `${this._translate.instant('ALERT.MESSAGE.INVALID')}`
          + ` ${this._translate.instant(response.message)}`)
      }

      const currentUser = JSON.parse(localStorage.getItem('currentUser'))
      currentUser.operator = operator
      localStorage.setItem('currentUser', JSON.stringify(currentUser))
      this._translate.use(operator.locale)
      this.alert = new Alert({
        type: AlertType.SUCCESS,
        message: this._translate.instant(
          `ALERT.MESSAGE.PREFERENCES_UPDATED`)
      })
      this.preferencesForm.markAsPristine()
      this.loading = false
      return this.alert.present()
    } catch (error) {
      this.openErrorAlert(
        `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${this._translate.instant(error.error.message)}`
        )
    }
  }

  private openErrorAlert (message: string): void {
    this.alert = new Alert({ type: AlertType.DANGER, message })
    return this.alert.present()
  }

  public getModal (): ModalComponent {
    return this.leaveModal
  }

  public getForm () {
    return [this.preferencesForm]
  }
}
