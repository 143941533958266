import { Component, ViewChild } from '@angular/core'
import { FormGroup, Validators, ValidationErrors, AbstractControl, FormControl, FormArray } from '@angular/forms'
import { MatRadioChange } from '@angular/material'
import { ActivatedRoute } from '@angular/router'
import { Operator } from '../../../classes/operator.class'
import { Role } from '../../../classes/role.class'
import { OperatorService } from '../../../services/operator.service'
import { UserService } from '../../../services/user.service'
import { RoleService } from '../../../services/role.service'
import { TranslateService } from '@ngx-translate/core'
import { UtilityService } from '../../../services/utility.service'
import { DateAdapter } from '@angular/material/core'
import { Alert } from 'src/app/classes/alert.class'
import { AlertType } from 'src/app/enums/alert-type.enum'
import { FormCanDeactivate } from '../../../guards/leave-page/form-can-deactivate'
import { ModalComponent } from 'src/app/components/modal/modal.component'
import { CheckboxContainerComponent } from 'src/app/components/checkbox-container/checkbox-container.component'
import { User } from 'src/app/classes/user.class'
import { SearchBoxComponent } from 'src/app/components/search-box/search-box.component'

@Component({
  selector: 'app-create-operator',
  templateUrl: './create-operator.component.html',
  styleUrls: ['./create-operator.component.scss']
})
export class CreateOperatorComponent extends FormCanDeactivate {
  @ViewChild('rolesComponent') rolesComponent: CheckboxContainerComponent
  @ViewChild('searchBox') searchBox: SearchBoxComponent
  @ViewChild(ModalComponent) leaveModal: ModalComponent

  public roles: Role[] = []
  public users: User[] = []
  public formattedUsers: any[] = []
  public updateMode = false
  public upgradeUser = false
  public loading = false
  private usernames: String[] = []
  public alert: Alert
  public createOperatorForm: FormGroup = new FormGroup({
    _id: new FormControl(undefined),
    authn: new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      _id: new FormControl(undefined)
    }),
    password: new FormControl(
      '',
      [Validators.required, Validators.minLength(5)]
    ),
    passwordConfirm: new FormControl(
      '',
      [Validators.required, this.matchPassword('password')]
    ),
    firstname: new FormControl('', [Validators.required]),
    lastname: new FormControl('', [Validators.required]),
    username: new FormControl(
      '',
      [Validators.required, this.checkUsernameAvailability()]
    ),
    genre: new FormControl(undefined, [Validators.required]),
    newsletter: new FormControl(false)
  })

  constructor (
    private _translate: TranslateService,
    private _operator: OperatorService,
    private _user: UserService,
    private _role: RoleService,
    public _utility: UtilityService,
    private adapter: DateAdapter<any>,
    private route: ActivatedRoute
  ) {
    super()
    this.init()
  }

  private async init () {
    this.loading = true
    await this.getRoles()
    await this.getUsers()
    await this.getOperators()
    this.setUpdatePage()
    // TODO change locale by the user's preferences
    this.adapter.setLocale('en')
  }

  async setUpdatePage (): Promise<void> {
    this.route.url.subscribe(url => {
      if (!url[0].path.includes('update')) {
        this.loading = false
        return
      }
      this.updateMode = true
      this.createOperatorForm.removeControl('passwordConfirm')
      this.createOperatorForm.removeControl('password')

      this.route.params.subscribe(async ({ _id }) => {
        await this.patchValueIntoForm(_id)
        this.loading = false
      })
    })
  }

  public async saveData (): Promise<void> {
    this.loading = true
    try {
      const value = this.createOperatorForm.value
      let operator = new Operator({
        user: {
          ...value,
          authn: {
            ...value.authn,
            roles: this.rolesComponent.selected,
          },
          address: {
            ...value.address
          },
          genre: value.genre && value.genre.toUpperCase()
        },
        username: value.username
      })

      if (this.upgradeUser) {
        const responseUpgrade = await this._operator
          .upgradeUserToOperator(operator).toPromise()
        if (!responseUpgrade.valid) {
          return this.openErrorAlert(
            `${this._translate.instant('ALERT.MESSAGE.INVALID')}`
            + ` ${this._translate.instant(responseUpgrade.message)}`)
        }
        operator = new Operator({
          ...responseUpgrade.data,
          user: {
            ...responseUpgrade.data.user,
            authn: {
              ...operator.user.authn,
              email: this.createOperatorForm.value.authn.email,
              roles: this.rolesComponent.selected
            }
          }
        })
      }

      const response = (this.updateMode || this.upgradeUser)
        ? await this._operator
          .updateOperator(operator).toPromise()
        : await this._operator
          .createOperator(operator, value.password).toPromise()

      if (response.invalid) {
        return this.openErrorAlert(
          `${this._translate.instant('ALERT.MESSAGE.INVALID')}`
          + ` ${this._translate.instant(response.message)}`)
      }

      this.alert = new Alert({
        type: AlertType.SUCCESS,
        message: this._translate.instant(
          `ALERT.MESSAGE.OPERATOR_` + (this.upgradeUser ? 'UPGRADED' : this.updateMode ? `UPDATED` : `CREATED`))
      })
      if (!this.updateMode) {
        this.createOperatorForm.reset()
      }
      if (this.upgradeUser) {
        this.updateMode = true
        this.upgradeUser = false
        this.createOperatorForm.enable()
        this.patchValueIntoForm(operator.user.authn._id)
      }
      this.createOperatorForm.markAsPristine()
      this.loading = false
      return this.alert.present()
    } catch (error) {
      this.openErrorAlert(
        `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${this._translate.instant(error.statusText)}`
        // + ` ${this._translate.instant(error.error)}`
      )
    }
  }

  private async getOperators () {
    try {
      const response = await this._operator.getOperators().toPromise()
      response.valid
        ? this.usernames = response.data.map(({ username }) => username)
        : this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.INVALID')}`
          + ` ${this._translate.instant(response.message)}`)
    } catch (error) {
      this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${error.statusText || error}`)
    }
  }

  private async getUsers (page?: number) {
    try {
      const query: any = {}
      if (page) { query.page = page }
      const response = await this._user.searchUser(query).toPromise()
      if (!response.valid) {
        return this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.INVALID')}`
          + ` ${this._translate.instant(response.message)}`)
      }

      const data = response.data.data.filter(({ authn }) => authn)
      this.users = response.data.page === 1
        ? data
        : this.users.concat(data)

      if (response.data.hasNext) {
        return this.getUsers((page || 0) + 1)
      }
      this.formattedUsers = this.users
        .map(({ authn }) => ({ _id: authn._id, email: authn.email }))
    } catch (error) {
      this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${error.statusText || error}`)
    }
  }

  private async getRoles (page = 0) {
    try {
      const response = await this._role.searchRole({page}).toPromise()
      if (response.invalid) {
        this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.INVALID')}`
          + ` ${this._translate.instant(response.message)}`)
        return
      }
      if (!page) {
        this.roles = response.data.data.sort(
          ({ name: name1 }, { name: name2 }) =>
          this._translate.instant(name1)
          .localeCompare(this._translate.instant(name2)))
      } else {
        this.roles = this.roles.concat(response.data.data)
        this.roles.sort(
          ({ name: name1 }, { name: name2 }) =>
        this._translate.instant(name1)
        .localeCompare(this._translate.instant(name2)))
      }
      if (response.data.hasNext) {
        this.getRoles(page + 1)
      }
    } catch (error) {
      this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${error.statusText || error}`)
      return
    }
  }

  private matchPassword (
    password: string
  ): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null =>
      (
        !control.value ||
        !control.parent.controls[password].value ||
        control.parent.controls[password].value === control.value
      )
        ? null
        : { matchPassword: true }
  }

  private checkUsernameAvailability (
  ): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return (
        !control.value ||
        !this.usernames.includes(control.value)
      )
        ? null
        : { matchValues: true }
    }
  }

  public async patchValueIntoForm (_id: string): Promise<void> {
    try {
      const response = await this._operator.getOperator(_id).toPromise()
      if (response.valid) {
        const operator = response.data

        // role data needs to bei mapped to check the right boxes
        if (operator.user.authn.roles.length) {
          this.rolesComponent
            .setSelected(operator.user.authn.roles.map(({ _id }) => _id))
        }
        this.usernames = this.usernames.filter(u => u !== operator.username)

        this.createOperatorForm.patchValue({
          ...operator.user,
          username: operator.username
        })
        return
      } else {
        return this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.INVALID')}`
          + ` ${this._translate.instant(response.message)}`)
      }
    } catch (error) {
      return this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${error.statusText || error}`)
    }
  }

  public setAllRoles (value: boolean): void {
    (this.createOperatorForm.controls.roles as FormArray)
      .controls.forEach((control) => control.setValue(value))
  }

  public isFalse (value: boolean): boolean {
    return !value
  }

  public handleUpgrade (event: MatRadioChange) {
    this.upgradeUser = !this.upgradeUser
    this.createOperatorForm.reset()
    if (this.upgradeUser) {
      this.createOperatorForm.disable()

      this.createOperatorForm.controls['authn'].enable()
      this.createOperatorForm.controls['address'].enable()
      this.createOperatorForm.controls['formattedAddress'].enable()
    } else {
      this.createOperatorForm.enable()
    }
  }

  public handleOutput (event) {
    const user = this.users
      .find(({ authn }) => authn._id === event._id.toString())
    // role data needs to bei mapped to check the right boxes
    if (user.authn.roles.length) {
      this.rolesComponent
        .setSelected(user.authn.roles.map(({ _id }) => _id))
    }
    this.createOperatorForm.patchValue({
      authn: { _id: event._id, email: event.email },
      ...user
    })
    this.createOperatorForm.controls['username'].enable()
    this.createOperatorForm.controls['genre'].enable()
  }

  // needed here to also set searchBox touched
  public markFormGroupTouched (formGroup: FormGroup): void {
    (<any>Object).values(formGroup.controls).forEach((control) => {
      control.markAsTouched()

      if (control.controls) {
        this.markFormGroupTouched(control)
      }
    })

    if (this.searchBox) {
      this.searchBox.touched = true
    }
  }

  private openErrorAlert (message: string): void {
    this.alert = new Alert({ type: AlertType.DANGER, message })
    this.loading = false
    return this.alert.present()
  }

  public getModal (): ModalComponent {
    return this.leaveModal
  }

  public getForm () {
    return [this.createOperatorForm]
  }

  // reset helper
  public reset () {
    this.createOperatorForm.reset()
    this.createOperatorForm.markAsPristine()
    this.createOperatorForm.markAsUntouched()
    if (this.upgradeUser) {
      this.searchBox.reset()
    }
    this.rolesComponent.reset()
  }
}
