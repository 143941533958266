import { Place } from './place.class'
import { Role } from './role.class'

export class Contact {
  username: string
  email: string
  firstname: string
  lastname: string
  address: Place
  roles: Role[]
  avatar: string

  constructor (obj: any) {
    const properties = ['username', 'email', 'firstname',  'lastname', 'avatar']

    for (const property in obj) {
      if (properties.includes(property)) {
        this[property] = obj[property]
      }
    }

    if (obj.address) {
      this.address = new Place(obj.address)
    }

    if (obj.roles) {
      this.roles = obj.roles.map(role => new Role(role))
    }
  }

  toString (): string {
    return `${this.firstname} ${this.lastname} (${this.username})`
  }
}
