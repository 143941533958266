// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: 'https://gateway.web-dev2.eloaded.eu',
  archContext: {
    authn: '/authns',
    activity: '/activities',
    role: '/roles',
    permission: '/permissions',
    banner: '/banner'
  },
  stationContext: {
    station: '/station'
  },
  accountContext: {
    account: '/account',
    customer: '/account/customer',
    device: '/account/device',
    paymentprovider: '/account/paymentprovider',
    admin: '/account/admin',
    car: '/account/car'
  },
  mailContext: {
    mail: '/mail'
  },
  plugContext: {
    plug: '/plug'
  },
  solarContext: {
    solar: '/solar'
  },
  echargeContext: {
    echarge: '/echarge',
    transaction: '/echarge/transaction',
    connection: '/echarge/connection',
    park: '/echarge/park',
    zone: '/echarge/zone'
  },
  productContext: {
    category: '/product/category',
    product: '/product/product',
    vendor: '/product/vendor'
  },
  orderContext: {
    order: '/order',
    pos: '/order/pos'
  },
  chargingWeb: {
    baseUrl: 'https://charge-dev2.eloaded.eu',
    station: '/station',
    charging: '/charge'
  }
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`,
 * `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a
 * negative impact on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
