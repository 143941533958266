import { Component, ViewChild } from '@angular/core'
import { FormGroup, FormControl } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { OperatorService } from '../../../services/operator.service'
import { RoleService } from 'src/app/services/role.service'
import { MatPaginator, MatTableDataSource } from '@angular/material'
import { Operator } from 'src/app/classes/operator.class'
import { Role } from 'src/app/classes/role.class'
import { Modal } from 'src/app/classes/modal.class'
import { Alert } from 'src/app/classes/alert.class'
import { AlertType } from 'src/app/enums/alert-type.enum'
import { CheckboxContainerComponent } from 'src/app/components/checkbox-container/checkbox-container.component'
import { DateFormat } from 'src/app/enums/date-format.enum'

@Component({
  selector: 'app-search-operator',
  templateUrl: './search-operator.component.html',
  styleUrls: ['./search-operator.component.scss']
})
export class SearchOperatorComponent {
  @ViewChild('rolesComponent') rolesComponent: CheckboxContainerComponent
  @ViewChild(MatPaginator) paginator: MatPaginator

  public searchOperatorForm: FormGroup = new FormGroup({
    email: new FormControl(),
    firstname: new FormControl(),
    lastname: new FormControl(),
    username: new FormControl(),
    genre: new FormControl(),
    newsletter: new FormControl(),
    locale: new FormControl()
  })
  public displayedColumns = ['EMAIL', 'USERNAME', 'FIRSTNAME', 'LASTNAME',
    'ADDRESS', 'BIRTHDAY', 'SEX', 'NEWSLETTER', 'BUTTONS']
  public dataSource: MatTableDataSource<Operator>
  public loading = false
  public maxDate: Date = new Date()
  public roles: Role[] = []
  public modal: Modal
  public alert: Alert

  public DateFormat = DateFormat

  constructor (
    private _translate: TranslateService,
    private _operator: OperatorService,
    private _role: RoleService
  ) {
    this.getRoles()
    this.maxDate.setFullYear(this.maxDate.getFullYear() - 18)
  }

  public async search (): Promise<void> {
    try {
      this.loading = true
      const values = this.searchOperatorForm.value
      const query = {}

      if (values.locale) { query['locale'] = values.locale }
      if (values.newsletter !== null) {
        query['newsletter'] = values.newsletter
      }
      if (this.rolesComponent.selected.length) {
        query['roles'] = this.rolesComponent.selected
      }
      if (values.email) { query['email'] = values.email }
      if (values.firstname) { query['firstname'] = values.firstname }
      if (values.lastname) { query['lastname'] = values.lastname }
      if (values.username) { query['username'] = values.username }
      if (values.address) { query['address'] = values.address }
      if (values.birthday) { query['birthday'] = values.birthday }
      if (values.genre) { query['genre'] = values.genre }

      const results = await this._operator.searchOperator(query).toPromise()

      if (results.invalid) {
        this.alert = new Alert({
          type: AlertType.DANGER,
          message: `${this._translate.instant('ALERT.MESSAGE.INVALID')} ${results.message}`
        })
        this.alert.present()
        this.loading = false
        return
      }
      this.dataSource = new MatTableDataSource(results.data)
      this.dataSource.paginator = this.paginator
      this.loading = false
    } catch (error) {
      this.alert = new Alert({
        type: AlertType.DANGER,
        message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
      })
      this.alert.present()
      this.loading = false
    }
  }

  public applyFilter (filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase()
  }

  public showModal (el: Operator): void {
    this.modal = new Modal({
      title: this._translate.instant('MODAL.TITLE.DELETE_OPERATOR'),
      body: `${this._translate.instant('MODAL.BODY.DELETE_OPERATOR')} ${el.user.firstname} ${el.user.lastname}?`,
      buttons: [{ title: this._translate.instant('BUTTON.DELETE') }],
      data: el.user.authn._id
    })
    this.modal.confirm()
  }

  public async delete (_id: string): Promise<boolean> {
    try {
      const results = await this._operator
        .deleteOperator(_id).toPromise()

      if (results.invalid) {
        this.alert = new Alert({
          type: AlertType.DANGER,
          message: `${this._translate.instant('ALERT.MESSAGE.INVALID')} ${results.message}`
        })
        this.alert.present()
        return false
      }

      this.dataSource.data = this.dataSource.data
        .filter(({ user }) => user.authn._id !== _id)

      this.alert = new Alert({
        type: AlertType.SUCCESS,
        message: this._translate.instant('ALERT.MESSAGE.OPERATOR_DELETED')
      })
      this.alert.present()

      return true
    } catch (error) {
      this.alert = new Alert({
        type: AlertType.DANGER,
        message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
      })
      this.alert.present()
      return false
    }
  }

  private async getRoles (page = 0): Promise<boolean> {
    try {
      const results = await this._role.searchRole({page}).toPromise()
      if (results.invalid) {
        this.alert = new Alert({
          type: AlertType.DANGER,
          message: `${this._translate.instant('ALERT.MESSAGE.INVALID')} ${results.message}`
        })
        this.alert.present()
        return false
      }
      if (!page) {
        this.roles = results.data.data.sort((r1, r2) =>
          r1.name.localeCompare(r2.name))
      } else {
        this.roles = this.roles.concat(results.data.data)
        this.roles.sort((r1, r2) =>
          r1.name.localeCompare(r2.name))
      }
      if (results.data.hasNext) {
        this.getRoles(page + 1)
      }
      return true
    } catch (error) {
      this.alert = new Alert({
        type: AlertType.DANGER,
        message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
      })
      this.alert.present()
      return false
    }
  }
}
