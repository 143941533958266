import { Component, ViewChild } from '@angular/core'
import { FormGroup, Validators, FormControl, FormArray, AbstractControl, ValidationErrors } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { StationService } from '../../../services/station.service'
import { UtilityService } from '../../../services/utility.service'
import { Alert } from 'src/app/classes/alert.class'
import { AlertType } from 'src/app/enums/alert-type.enum'
import { FormCanDeactivate } from '../../../guards/leave-page/form-can-deactivate'
import { ModalComponent } from 'src/app/components/modal/modal.component'
import { Coords } from 'src/app/classes/coords.class'
import { Country } from 'src/app/enums/country.enum'
import { Weekday } from 'src/app/enums/weekday.enum'

@Component({
  selector: 'app-create-station',
  templateUrl: './create-station.component.html',
  styleUrls: ['./create-station.component.scss']
})

export class CreateStationComponent
  extends FormCanDeactivate {

  @ViewChild(ModalComponent) leaveModal: ModalComponent

  private periodRegex = /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/

  public countries = Object.keys(Country).filter((v) => isNaN(Number(v)))

  public weekdays = Object.keys(Weekday).filter((v) => isNaN(Number(v)))

  public originalCoords: Coords

  public createStationForm = new FormGroup({
    _id: new FormControl(undefined),
    name: new FormControl('', Validators.required),
    coords: new FormGroup({
      lat: new FormControl('', Validators.required),
      lng: new FormControl('', Validators.required)
    }),
    address: new FormControl('', Validators.required),
    city: new FormControl('', Validators.required),
    postalCode: new FormControl(),
    state: new FormControl(),
    country: new FormControl(undefined, Validators.required),
    active: new FormControl(true, Validators.required),
    publish: new FormControl(true, Validators.required),
    openingTimes: new FormGroup({
      twentyfourseven: new FormControl(true, Validators.required),
      regularHours: new FormArray(
        this.weekdays.map((e, i) => new FormGroup({
          weekday: new FormControl(i + 1, Validators.required),
          open: new FormControl(false),
          periodBegin: new FormControl(
            '00:00',
            [Validators.required, Validators.pattern(this.periodRegex)]),
          periodEnd: new FormControl(
            '00:00',
            [Validators.required, Validators.pattern(this.periodRegex)])
        }, this.validPeriod))
      )
    }, this.openingTimesValidator),
    chargingWhenClosed: new FormControl()
  })
  public loading = false
  public updateMode = false

  public alert: Alert

  constructor (
    private _station: StationService,
    private _translate: TranslateService,
    public _utility: UtilityService,
    private route: ActivatedRoute
  ) {
    super()
    this.init()
  }

  openingTimesValidator (control: AbstractControl): ValidationErrors | null {
    const { twentyfourseven, regularHours } = control.value
    if (twentyfourseven) {
      return null
    }
    if (regularHours.filter(({ open }) => open).length) {
      return null
    }
    return { regularHours: true }
  }

  validPeriod (control: AbstractControl): ValidationErrors | null {
    const { open, periodBegin, periodEnd } = control.value
    if (!open) {
      return null
    }
    const beginTime = new Date(`2000-01-01T${periodBegin}:00.000Z`).getTime()
    const endTime = new Date(`2000-01-01T${periodEnd}:00.000Z`).getTime()
    if (beginTime < endTime) {
      return null
    }
    return { validPeriod: true }
  }

  async init () {
    this.loading = true
    await this.setUpdatePage()
    this.createStationForm.get('openingTimes.twentyfourseven').valueChanges
      .subscribe((v) => v
        ? this.createStationForm.get('openingTimes.regularHours').disable()
        : this.createStationForm.get('openingTimes.regularHours').enable())
  }

  async setUpdatePage (): Promise<void> {
    this.route.url.subscribe(url => {
      if (!url[0].path.includes('update')) {
        this.loading = false
        this.originalCoords = new Coords({})
        return
      }
      this.updateMode = true

      this.route.params.subscribe(async ({ _id }) => {
        await this.patchValueIntoForm(_id)
        this.loading = false
      })
    })
  }

  public async saveData (): Promise<void> {
    this.loading = true
    try {
      const station = this.createStationForm.value
      if (station.openingTimes.twentyfourseven) {
        delete station.openingTimes.regularHours
      } else {
        station.openingTimes.regularHours = station.openingTimes.regularHours
          .filter(({ open }) => open)
          .map(d => ({ ...d, open: undefined }))
      }
      const response = (this.updateMode)
        ? await this._station.updateStation(station).toPromise()
        : await this._station.createStation(station).toPromise()

      if (response.invalid) {
        this.loading = false
        return this.openErrorAlert(
          `${this._translate.instant('ALERT.MESSAGE.INVALID')}`
          + ` ${this._translate.instant(response.message)}`)
      }
      this.alert = new Alert({
        type: AlertType.SUCCESS,
        message: this._translate.instant(
          `ALERT.MESSAGE.STATION_` + (this.updateMode ? `UPDATED` : `CREATED`))
      })
      if (!this.updateMode) {
        this.createStationForm.reset()
      }
      this.createStationForm.markAsPristine()
      this.loading = false
      return this.alert.present()
    } catch (error) {
      this.openErrorAlert(
        `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${this._translate.instant(error.statusText)}`)
    }
  }

  async patchValueIntoForm (_id: string) {
    try {
      const response = await this._station.getStation(_id).toPromise()
      if (response.valid) {
        const station = response.data
        this.originalCoords = station.coords
        station.openingTimes.regularHours = this.createStationForm
          .get('openingTimes.regularHours').value
          .map(day => station.openingTimes.regularHours
              .find(d => d.weekday === day.weekday) || day)
          .map(d => ({ ...d, open: d.periodBegin !== d.periodEnd }))
        this.createStationForm.patchValue(station)
      } else {
        this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.INVALID')}`
          + ` ${this._translate.instant(response.message)}`)
      }
    } catch (error) {
      this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${error.statusText || error}`)
    }
  }

  private openErrorAlert (message: string): void {
    this.alert = new Alert({ type: AlertType.DANGER, message })
    return this.alert.present()
  }

  // here necessary and can not be used from service since addressValidation
  // also needs to be set
  public markFormGroupTouched (formGroup: FormGroup): void {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched()

      if (control.controls) {
        this.markFormGroupTouched(control)
      }
    })
  }

  public getModal (): ModalComponent {
    return this.leaveModal
  }

  public getForm () {
    return [this.createStationForm]
  }
}
