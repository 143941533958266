import { Component, OnInit, DoCheck } from '@angular/core'
import { AuthService } from '../../services/auth.service'
import { OperatorService } from '../../services/operator.service'
import { Operator } from 'src/app/classes/operator.class'
import { Router } from '@angular/router'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, DoCheck {
  operator: Operator

  constructor (
    private _auth: AuthService,
    private router: Router,
    private _operator: OperatorService
  ) { }

  async ngOnInit () {
    try {
      const currentUser = JSON.parse(localStorage.getItem('currentUser'))

      if (!currentUser || !currentUser.data) {
        this.router.navigate(['/login'])
        console.log('error token not found')
        return
      }

      const results = await this._operator.getOperatorInfo().toPromise()

      if (results.message === 'OK') {
        currentUser.operator = results.data
        localStorage.setItem('currentUser', JSON.stringify(currentUser))
        this.operator = currentUser.operator
        this._operator.changeOperatorInfo(currentUser.operator)
      } else {
        localStorage.removeItem('currentUser')
        this.router.navigate(['/login'])
        console.log(results.message)
      }
    } catch (error) {
      localStorage.removeItem('currentUser')
      this.router.navigate(['/login'])
      console.log(error)
    }
  }

  public toggleSidebar () {
    const sidebar = document.getElementById('sidebar-container')
    if (sidebar.classList.contains('visible')) {
      sidebar.classList.remove('visible')
    } else {
      sidebar.classList.add('visible')
    }
  }

  ngDoCheck () {
    this._operator.currentOperator.subscribe(operator => {
      this.operator = operator
    })
  }

  public logout () {
    this._auth.logout()
    this.router.navigate(['/login'])
  }
}
