import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { LoginComponent } from './pages/login/login.component'
import { SignupComponent } from './pages/signup/signup.component'
import { DashboardComponent } from './pages/dashboard/dashboard.component'
import { CreateCarComponent } from './pages/cars/create-car/create-car.component'
import { SearchCarComponent } from './pages/cars/search-car/search-car.component'
import { AuthGuard } from './guards/auth.guard'
import { LeavePageGuard } from './guards/leave-page/leave-page.guard'
import { CreatePlugComponent } from './pages/plugs/create-plug/create-plug.component'
import { SearchPlugComponent } from './pages/plugs/search-plug/search-plug.component'
import { CreateOperatorComponent } from './pages/operators/create-operator/create-operator.component'
import { SearchOperatorComponent } from './pages/operators/search-operator/search-operator.component'
import { ProfileComponent } from './pages/profile/profile.component'
import { ContactsComponent } from './pages/contacts/contacts.component'
import { PreferencesComponent } from './pages/preferences/preferences.component'
import { CreateActivityComponent } from './pages/security/activities/create-activity/create-activity.component'
import { SearchActivityComponent } from './pages/security/activities/search-activity/search-activity.component'
import { SearchPermissionComponent } from './pages/security/permissions/search-permission/search-permission.component'
import { CreatePermissionComponent } from './pages/security/permissions/create-permission/create-permission.component'
import { CreateRoleComponent } from './pages/security/roles/create-role/create-role.component'
import { SearchRoleComponent } from './pages/security/roles/search-role/search-role.component'
import { CreateUserComponent } from './pages/users/create-user/create-user.component'
import { SearchUserComponent } from './pages/users/search-user/search-user.component'
import { CreateEchargerComponent } from './pages/echargers/create-echarger/create-echarger.component'
import { SearchEchargerComponent } from './pages/echargers/search-echarger/search-echarger.component'
import { EchargerDetailComponent } from './pages/echargers/echarger-detail/echarger-detail.component'
import { VirtualEchargerComponent } from './pages/echargers/virtual-echarger/virtual-echarger.component'
import { CreateStationComponent } from './pages/stations/create-station/create-station.component'
import { SearchStationComponent } from './pages/stations/search-station/search-station.component'
import { CreateVendorComponent } from './pages/vendors/create-vendor/create-vendor.component'
import { SearchVendorComponent } from './pages/vendors/search-vendor/search-vendor.component'
import { SystemTestComponent } from './pages/monitoring/system-test/system-test.component'
import { SearchParkComponent } from './pages/park/search-park/search-park.component'
import { CreateParkComponent } from './pages/park/create-park/create-park.component'
import { SearchParkStationComponent } from './pages/park/search-park-station/search-park-station.component'
import { LinkZoneComponent } from './pages/park/link-zone/link-zone.component'
import { BannerComponent } from './pages/mobile-app/banner/banner.component'
import { SearchCustomerComponent } from './pages/customers/search/search-customer.component'
import { CreateCustomerComponent } from './pages/customers/create/create-customer.component'
import { CreatePrivilegeComponent } from './pages/privileges/create-privilege/create-privilege.component'
import { SearchPrivilegeComponent } from './pages/privileges/search-privilege/search-privilege.component'
import { ChargingHistoryComponent } from './pages/charging-history/charging-history/charging-history.component'
import { ChargingHistoryDetailComponent } from './pages/charging-history/charging-history-detail/charging-history-detail.component'
import { CreateDeviceComponent } from './pages/devices/create/create-device.component'
import { SearchDeviceComponent } from './pages/devices/search/search-device.component'
import { SearchPaymentproviderComponent } from './pages/payment-providers/search/search-payment-provider.component'
import { CreatePaymentproviderComponent } from './pages/payment-providers/create/create-payment-provider.component'
import { ChargingHistoryAdminComponent } from './pages/charging-history-admin/charging-history-admin/charging-history-admin.component'
import { ChargingHistoryAdminDetailComponent } from './pages/charging-history-admin/charging-history-admin-detail/charging-history-admin-detail.component'

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/login'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'signup',
    component: SignupComponent
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'create-car',
    component: CreateCarComponent,
    canActivate: [ AuthGuard ],
    canDeactivate: [ LeavePageGuard ]
  },
  {
    path: 'update-car/:_id',
    component: CreateCarComponent,
    canActivate: [ AuthGuard ],
    canDeactivate: [ LeavePageGuard ]
  },
  {
    path: 'search-car',
    component: SearchCarComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'create-activity',
    component: CreateActivityComponent,
    canActivate: [ AuthGuard ],
    canDeactivate: [ LeavePageGuard ]
  },
  {
    path: 'update-activity/:_id',
    component: CreateActivityComponent,
    canActivate: [ AuthGuard ],
    canDeactivate: [ LeavePageGuard ]
  },
  {
    path: 'search-activity',
    component: SearchActivityComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'create-permission',
    component: CreatePermissionComponent,
    canActivate: [ AuthGuard ],
    canDeactivate: [ LeavePageGuard ]
  },
  {
    path: 'update-permission/:_id',
    component: CreatePermissionComponent,
    canActivate: [ AuthGuard ],
    canDeactivate: [ LeavePageGuard ]
  },
  {
    path: 'search-permission',
    component: SearchPermissionComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'create-role',
    component: CreateRoleComponent,
    canActivate: [ AuthGuard ],
    canDeactivate: [ LeavePageGuard ]
  },
  {
    path: 'update-role/:_id',
    component: CreateRoleComponent,
    canActivate: [ AuthGuard ],
    canDeactivate: [ LeavePageGuard ]
  },
  {
    path: 'search-role',
    component: SearchRoleComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'create-operator',
    component: CreateOperatorComponent,
    canActivate: [ AuthGuard ],
    canDeactivate: [ LeavePageGuard ]
  },
  {
    path: 'update-operator/:_id',
    component: CreateOperatorComponent,
    canActivate: [ AuthGuard ],
    canDeactivate: [ LeavePageGuard ]
  },
  {
    path: 'search-operator',
    component: SearchOperatorComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'create-user',
    component: CreateUserComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'update-user/:_id',
    component: CreateUserComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'search-user',
    component: SearchUserComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'create-plug',
    component: CreatePlugComponent,
    canActivate: [ AuthGuard ],
    canDeactivate: [ LeavePageGuard ]
  },
  {
    path: 'update-plug/:_id',
    component: CreatePlugComponent,
    canActivate: [ AuthGuard ],
    canDeactivate: [ LeavePageGuard ]
  },
  {
    path: 'search-plug',
    component: SearchPlugComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [ AuthGuard ],
    canDeactivate: [ LeavePageGuard ]
  },
  {
    path: 'contacts',
    component: ContactsComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'preferences',
    component: PreferencesComponent,
    canActivate: [ AuthGuard ],
    canDeactivate: [ LeavePageGuard ]
  },
  {
    path: 'create-echarge',
    component: CreateEchargerComponent,
    canActivate: [ AuthGuard ],
    canDeactivate: [ LeavePageGuard ]
  },
  {
    path: 'search-echarge',
    component: SearchEchargerComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'update-echarge/:_id',
    component: CreateEchargerComponent,
    canActivate: [ AuthGuard ],
    canDeactivate: [ LeavePageGuard ]
  },
  {
    path: 'echarger-detail/:_id',
    component: EchargerDetailComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'create-station',
    component: CreateStationComponent,
    canActivate: [ AuthGuard ],
    canDeactivate: [ LeavePageGuard ]
  },
  {
    path: 'update-station/:_id',
    component: CreateStationComponent,
    canActivate: [ AuthGuard ],
    canDeactivate: [ LeavePageGuard ]
  },
  {
    path: 'search-station',
    component: SearchStationComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'create-vendor',
    component: CreateVendorComponent,
    canActivate: [ AuthGuard ],
    canDeactivate: [ LeavePageGuard ]
  },
  {
    path: 'update-vendor/:_id',
    component: CreateVendorComponent,
    canActivate: [ AuthGuard ],
    canDeactivate: [ LeavePageGuard ]
  },
  {
    path: 'search-vendor',
    component: SearchVendorComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'system-test',
    component: SystemTestComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'search-park',
    component: SearchParkComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'create-park',
    component: SearchParkStationComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'create-park/:_id',
    component: CreateParkComponent,
    canActivate: [ AuthGuard ],
    canDeactivate: [ LeavePageGuard ]
  },
  {
    path: 'update-park/:_id',
    component: CreateParkComponent,
    canActivate: [ AuthGuard ],
    canDeactivate: [ LeavePageGuard ]
  },
  {
    path: 'link-zone/:_id',
    component: LinkZoneComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'search-customer',
    component: SearchCustomerComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'create-customer',
    component: CreateCustomerComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'update-customer/:_id',
    component: CreateCustomerComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'search-device',
    component: SearchDeviceComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'create-device',
    component: CreateDeviceComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'search-paymentprovider',
    component: SearchPaymentproviderComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'create-paymentprovider',
    component: CreatePaymentproviderComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'update-device/:_id',
    component: CreateDeviceComponent,
    canActivate: [ AuthGuard ]
  },
  { path: 'banner', component: BannerComponent, canActivate: [ AuthGuard ] },
  {
    path: 'create-privilege',
    component: CreatePrivilegeComponent,
    canActivate: [ AuthGuard ],
    canDeactivate: [ LeavePageGuard ]
  },
  {
    path: 'search-privilege',
    component: SearchPrivilegeComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'update-privilege/:_id',
    component: CreatePrivilegeComponent,
    canActivate: [ AuthGuard ],
    canDeactivate: [ LeavePageGuard ]
  },
  {
    path: 'charging-history',
    component: ChargingHistoryComponent,
    canActivate: [ AuthGuard ],
    data: { reuseComponent: true }
  },
  {
    path: 'charging-history/:_id',
    component: ChargingHistoryDetailComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'charging-history-admin',
    component: ChargingHistoryAdminComponent,
    canActivate: [ AuthGuard ],
    data: { reuseComponent: true }
  },
  {
    path: 'charging-history-admin/:_id',
    component: ChargingHistoryAdminDetailComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'virtual-echarger',
    component: VirtualEchargerComponent,
    canActivate: [ AuthGuard ]
  },
  { path: '**', redirectTo: '/login' }
]

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})

export class AppRoutingModule { }
