import { Component, OnInit } from '@angular/core'
import { Operator } from 'src/app/classes/operator.class'
import { OperatorService } from 'src/app/services/operator.service'
import { Router } from '@angular/router'
import { AuthService } from 'src/app/services/auth.service'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  operator: Operator

  constructor (
    private _operator: OperatorService,
    private router: Router,
    private _auth: AuthService) { }

  async ngOnInit () {
    try {
      const results = await this._operator.getOperatorInfo().toPromise()
      if (results.message !== 'OK') {
        console.log('error token not found')
        this.router.navigate(['/login'])
        return
      }

      const currentUser = JSON.parse(localStorage.getItem('currentUser'))
      if (currentUser && currentUser.data) {
        currentUser.operator = results.data
        localStorage.setItem('currentUser', JSON.stringify(currentUser))
        this.operator = currentUser.operator
        this._operator.changeOperatorInfo(currentUser.operator)
      } else {
        console.log('error token not found')
        this.router.navigate(['/login'])
      }
    } catch (error) {
      console.log(error)
      this._auth.currentUserSubject.next(null)
      this.router.navigate(['/login'])
    }
  }
}
