import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'
import { Echarger } from 'src/app/classes/echarger.class'
import { Response } from 'src/app/classes/response.class'
import { map, switchMap, retry } from 'rxjs/operators'
import { Observable, timer } from 'rxjs'
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class EchargerService {
  private baseUrl =
    `${environment.baseUrl}${environment.echargeContext.echarge}`

  constructor (
    private http: HttpClient
  ) { }

  public createCharge (echarger: any): Observable<Response<Echarger>> {
    return this.http
      .post<Response<Echarger>>(this.baseUrl, {
        ...echarger,
        vendor: echarger.vendor._id
      })
      .pipe(map(({ message, data }) =>
        new Response<Echarger>(message, data && new Echarger(data))
      ))
  }

  public searchEcharger (form): Observable<Response<Echarger[]>> {
    return this.http
      .post<Response<Echarger[]>>(`${this.baseUrl}/search`, form)
      .pipe(map(({ message, data }) =>
        new Response<Echarger[]>(
          message,
          data && data.map((echarger) => new Echarger(echarger))
        )
      ))
  }

  public updateEcharger (echarger: any): Observable<Response<Echarger>> {
    return this.http
      .patch<Response<Echarger>>(`${this.baseUrl}`, { ...echarger, vendor: echarger.vendor._id})
      .pipe(map(({ data, message }) =>
        new Response<Echarger>(message, data && new Echarger(data))
      ))
  }

  public getEcharger (_id: string): Observable<Response<Echarger>> {
    return this.http
      .get<Response<Echarger>>(`${this.baseUrl}/${_id}`)
      .pipe(map(({ message, data }) =>
        new Response<Echarger>(message, new Echarger(data))
      ))
  }

  public deleteEcharger (_id: string): Observable<Response<Echarger>> {
    return this.http
      .delete<Response<Echarger>>(`${this.baseUrl}/${_id}`)
      .pipe(map(({ message, data }) =>
        new Response<Echarger>(message, new Echarger(data))
      ))
  }

  public sendCommand (form): Observable<Response<string>> {
    return this.http
      .post<Response<string>>(`${this.baseUrl}/sendCommand`, form)
      .pipe(map(({ message, data }) =>
        new Response<string>(message, data)
      ))
  }

  public virtualEcRequestRegister (
    openSocketNr: number,
    protocolVersion: string
  ): Observable<Response<string>> {
    return this.http
      .post<Response<string>>(
        `${this.baseUrl}/virtual/command/${openSocketNr}`,
        { cmdId: 44, protocol: protocolVersion }
      )
      .pipe(map(({ message, data }) =>
        new Response<string>(message, data)
      ))
  }

  public virtualEcRequestPrecharge (openSocketNr: number, form)
    : Observable<Response<string>> {
    return this.http
      .post<Response<string>>(
        `${this.baseUrl}/virtual/command/${openSocketNr}`,
        { cmdId: 42, ...form }
      )
      .pipe(map(({ message, data }) =>
        new Response<string>(message, data)
      ))
  }

  public virtualEcResponseChargeInfo (openSocketNr: number, form)
    : Observable<Response<string>> {
    return this.http
      .post<Response<string>>(
        `${this.baseUrl}/virtual/command/${openSocketNr}`,
        { cmdId: 41, ...form }
      )
      .pipe(map(({ message, data }) =>
        new Response<string>(message, data)
      ))
  }

  public virtualEcConnect (selectedzoneId: string, echargerCode: string)
    : Observable<Response<{ echarger: string, socket: number }>> {
    return this.http
      .post<Response<{ echarger: string, socket: number }>>(
        `${this.baseUrl}/virtual/connect`,
        { zoneId: selectedzoneId, echargerCode }
      )
      .pipe(map(({ message, data }) =>
        new Response<{ echarger: string, socket: number }>(message, data)
      ))
  }

  public virtualEcDisconnect (openSocketNr: number)
    : Observable<Response<string>> {
    return this.http
      .get<Response<string>>(`${this.baseUrl}/virtual/disconnect/${openSocketNr}`)
      .pipe(map(({ message, data }) =>
        new Response<string>(message, data)
      ))
  }

  public virtualEcGetContinuosLogs (
    openSocketNr: number,
    filter: string,
    tail: number
  ): Observable<Response<string>> {
    return timer(0, 2000).pipe(
      switchMap(() =>
        this.http.post<Response<string>>(
          `${this.baseUrl}/virtual/logs/${openSocketNr}`,
          { filter, tail }
        ),
      ),
      retry(3),
      map(({ message, data }) =>
        new Response<string>(message, data)
      )
    )
  }
}
