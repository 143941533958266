import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from '../../environments/environment'

import { Response } from '../classes/response.class'
import { Vendor } from '../classes/vendor.class'

@Injectable({
  providedIn: 'root'
})
export class VendorService {
  private baseUrl = `${environment.baseUrl}${environment.echargeContext.echarge}${environment.productContext.vendor}`

  constructor (private http: HttpClient) { }

  public createVendor (vendor: Vendor): Observable<Response<Vendor>> {
    return this.http
      .post<Response<Vendor>>(this.baseUrl, vendor)
      .pipe(map(({ message, data }) =>
        new Response<Vendor>(message, new Vendor(data))))
  }

  public deleteVendor (_id: string): Observable<Response<Vendor>> {
    return this.http
      .delete<Response<Vendor>>(`${this.baseUrl}/${_id}`)
      .pipe(map(({ message, data }) =>
        new Response<Vendor>(message, new Vendor(data))))
  }

  public getVendor (_id: string): Observable<Response<Vendor>> {
    return this.http
      .get<Response<Vendor>>(`${this.baseUrl}/${_id}`)
      .pipe(map(({ message, data }) =>
        new Response<Vendor>(message, new Vendor(data))))
  }

  public searchVendor (vendor?: Vendor): Observable<Response<Vendor[]>> {
    return this.http
      .post<Response<Vendor[]>>(`${this.baseUrl}/search`, vendor)
      .pipe(map(({ message, data }) =>
      new Response<Vendor[]>(message, data.map(v => new Vendor(v)))))
  }

  public updateVendor (vendor: Vendor): Observable<Response<Vendor>> {
    return this.http
      .put<Response<Vendor>>(this.baseUrl, vendor)
      .pipe(map(({ message, data }) =>
        new Response<Vendor>(message, new Vendor(data))))
  }

  public getAllVendors (): Observable<Response<Vendor[]>> {
    return this.http
      .get<Response<Vendor[]>>(`${this.baseUrl}`)
      .pipe(map(({ message, data }) =>
        new Response(message, data && data.map((vendor) => new Vendor(vendor)))
      ))
  }
}
