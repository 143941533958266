import { Component, OnInit, OnDestroy } from '@angular/core'
import { Subscription } from 'rxjs'
import { OperatorService } from '../../services/operator.service'
import { TranslateService } from '@ngx-translate/core'
import { Alert } from 'src/app/classes/alert.class'
import { AlertType } from 'src/app/enums/alert-type.enum'
import { Contact } from 'src/app/classes/contact.class'

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = []
  public operators: Contact[]
  public loading = false
  public alert: Alert

  constructor (
    private _operator: OperatorService,
    // private _auth: AuthService,
    public _translate: TranslateService
  ) {

  }

  // ngOnInit () {
  //   const sub = this._operator.getOperators().subscribe(success => {
  //     console.log(success.data)
  //     this._auth.currentUser.subscribe(
  //     user => {this.operators = success.data
  //       .filter((operator) => operator._id !== user.operator._id)}
  //   )}, error => console.log(error))

  //   this.subscriptions.push(sub)
  // }

  async ngOnInit () {
    this.loading = true
    try {

      const response = await this._operator.getContacts().toPromise()
      if (response.invalid) {
        return this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.INVALID')}`
          + ` ${this._translate.instant(response.message)}`)
      }
      this.operators = response.data
    } catch (error) {
      return this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${error.statusText || error}`)
    }
  }

  ngOnDestroy () {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }

  private openErrorAlert (message: string): void {
    this.loading = false
    this.alert = new Alert({ type: AlertType.DANGER, message })
    return this.alert.present()
  }
}
