import { Injectable } from '@angular/core'
import { Observable, BehaviorSubject } from 'rxjs'
import { map } from 'rxjs/operators'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { OperatorService } from './operator.service'

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public currentUserSubject = new BehaviorSubject<any | null>(null)
  public currentUser: Observable<any>
  private baseUrl = `${environment.baseUrl}${environment.archContext.authn}`
  redirectUrl: string
  token: string

  get isLoggedIn () {
    return this.currentUserSubject.value
  }

  isAuthenticated () {
    return this.token !== null
  }

  constructor (
    private http: HttpClient,
    private _operator: OperatorService
  ) {
    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem('currentUser'))
    )
    this.currentUser = this.currentUserSubject.asObservable()
  }

  public login (email: string, password: string): Observable<any> {
    return this.http
    .put<any>(this.baseUrl, {
      email: email,
      password: password
    })
    .pipe(
      map(user => {
        if (user && user.message === 'OK') {
          this.currentUserSubject.next(user.data)
          localStorage.setItem('currentUser', JSON.stringify(user))
        }
        return user
      })
    )
  }

  public logout () {
    localStorage.removeItem('currentUser')
    this._operator.changeOperatorInfo(null)
    this.currentUserSubject.next(null)
  }

  public changePassword (oldPassword: string, newPassword: string)
    : Observable<any> {
    return this.http
      .patch<any>(`${this.baseUrl}/password`, {
        oldPassword: oldPassword,
        newPassword: newPassword
      })
  }

  public summary (): Observable<any> {
    return this.http
      .get<any>(`${environment.baseUrl}/summary`)
      .pipe(map(resp => resp))
  }
}
