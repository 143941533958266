import { Component, ViewChild, OnInit } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { MatDialog, MatDialogConfig, MatPaginator, MatTableDataSource } from '@angular/material'
import { TranslateService } from '@ngx-translate/core'
import { CustomerService } from '../../../services/customer.service'
import { Customer } from '../../../classes/customer.class'
import { Modal } from 'src/app/classes/modal.class'
import { Alert } from 'src/app/classes/alert.class'
import { AlertType } from 'src/app/enums/alert-type.enum'
import { Observable } from 'rxjs'
import { filter, map, startWith, switchMap } from 'rxjs/operators'
import { OperatorService } from 'src/app/services/operator.service'
import { Operator } from 'src/app/classes/operator.class'
import { CreateCustomerComponent } from '../create/create-customer.component'
import { OrderService } from 'src/app/services/order.service'
import { DateFormat } from 'src/app/enums/date-format.enum'
import { environment } from 'src/environments/environment'
import { environment as prodEnvironment } from 'src/environments/environment.prod'

@Component({
  selector: 'app-search-customer',
  templateUrl: './search-customer.component.html',
  styleUrls: ['./search-customer.component.scss']
})
export class SearchCustomerComponent implements OnInit {
  public displayedColumns: string[]
  public dataSource: MatTableDataSource<Customer>
  public loading = false
  public modal: Modal
  public alert: Alert
  public searchCustomerForm: FormGroup = new FormGroup({
    name: new FormControl(undefined),
    rootOperator: new FormControl(undefined)
  })

  @ViewChild(MatPaginator) paginator: MatPaginator

  public DateFormat = DateFormat

  public operators: Operator[]
  public filteredOperators: Observable<Operator[]>

  constructor (
    private _translate: TranslateService,
    private _customer: CustomerService,
    private _operator: OperatorService,
    private _order: OrderService,
    private _dialog: MatDialog
  ) { }

  async ngOnInit () {
    await this.getOperators()
    this.filteredOperators = this.searchCustomerForm
      .get('rootOperator').valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.email),
        map((value: string) => value
          ? this._filter(value)
          : this.operators.slice()
        )
      )
  }

  isProdEnv (): boolean {
    return environment.baseUrl === prodEnvironment.baseUrl
  }

  private async getOperators () {
    try {
      const res = await this._operator.searchOperator({}).toPromise()
      this.operators = res.data || []
    } catch (error) {
      this.alert = new Alert({
        type: AlertType.DANGER,
        message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
      })
      this.alert.present()
    }
  }

  public async create () {
    try {
      const dialogRef = this._dialog.open(CreateCustomerComponent, {
        panelClass: 'maxh-100-vh'
      })
      dialogRef.afterClosed()
        .pipe(
          filter((data) => !!data),
          switchMap((data) => this._customer.createCustomer(new Customer(data)))
        )
        .subscribe(customerResponse => {
          if (customerResponse.invalid) {
            this.alert = new Alert({
              type: AlertType.DANGER,
              message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${customerResponse.message}`
            })
            this.alert.present()
          }

          this.search()
        }, (error) => {
          this.alert = new Alert({
            type: AlertType.DANGER,
            message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
          })
          this.alert.present()
        })
    } catch (error) {
      this.alert = new Alert({
        type: AlertType.DANGER,
        message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
      })
      this.alert.present()
    }
  }

  public async update (customer: Customer) {
    try {
      const dialogConfig = new MatDialogConfig()
      dialogConfig.data = customer
      dialogConfig.panelClass = 'maxh-100-vh'
      const dialogRef = this._dialog.open(CreateCustomerComponent, dialogConfig)
      dialogRef.afterClosed()
        .pipe(
          filter((data) => !!data),
          switchMap((data) => this._customer.updateCustomer(new Customer(data)))
        )
        .subscribe(customerResponse => {
          if (customerResponse.invalid) {
            this.alert = new Alert({
              type: AlertType.DANGER,
              message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${customerResponse.message}`
            })
            this.alert.present()
          }

          this.search()
        }, (error) => {
          this.alert = new Alert({
            type: AlertType.DANGER,
            message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
          })
          this.alert.present()
        })
    } catch (error) {
      this.alert = new Alert({
        type: AlertType.DANGER,
        message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
      })
      this.alert.present()
    }
  }

  public showModal (el: Customer): void {
    this.modal = new Modal({
      title: this._translate.instant('MODAL.TITLE.DELETE_CUSTOMER'),
      body: `${this._translate.instant('MODAL.BODY.DELETE_CUSTOMER')} ${el.name}?`,
      buttons: [
        {
          title: this._translate.instant('BUTTON.DELETE')
        }
      ],
      data: el._id
    })

    this.modal.confirm()
  }

  public applyFilter (filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase()
  }

  public async delete (_id: string) {
    try {
      const result = await this._customer.deleteCustomer(_id).toPromise()
      if (result.invalid) {
        this.alert = new Alert({
          type: AlertType.DANGER,
          message: `${this._translate.instant('ALERT.MESSAGE.INVALID')} ${result.message}`
        })
        this.alert.present()
        return
      }
      this.dataSource.data = this.dataSource.data.filter(d => d._id !== _id)
      this.alert = new Alert({
        type: AlertType.SUCCESS,
        message: this._translate.instant('ALERT.MESSAGE.CUSTOMER_DELETED')
      })
      this.alert.present()
    } catch (error) {
      this.alert = new Alert({
        type: AlertType.DANGER,
        message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
      })
      this.alert.present()
    }
  }

  public async search () {
    this.loading = true
    try {
      const { value } = this.searchCustomerForm
      const customer = {
        ...value,
        rootOperator: value.rootOperator && value.rootOperator._id
      }
      Object.keys(customer).forEach(k => {
        if (!customer[k]) {
          delete customer[k]
        }
      })

      const result = await this._customer.searchCustomer(customer).toPromise()
      if (result.invalid) {
        this.alert = new Alert({
          type: AlertType.DANGER,
          message: `${this._translate.instant('ALERT.MESSAGE.INVALID')} ${result.message}`
        })
        this.alert.present()
        this.loading = false
        return
      }
      this.displayedColumns = [
        'NAME',
        'ROOTOPERATOR',
        'CREATIONDATE',
        'BUTTONS'
      ]
      this.dataSource = new MatTableDataSource(result.data.data)
      this.dataSource.paginator = this.paginator
      this.loading = false
    } catch (error) {
      this.alert = new Alert({
        type: AlertType.DANGER,
        message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
      })
      this.alert.present()
      this.loading = false
    }
  }

  public displayFn (operator: Operator): string {
    return (operator && operator.email) || ''
  }

  private _filter (input: string): Operator[] {
    const filterValue = input.toLowerCase()

    return this.operators.filter((operator: Operator) =>
      operator.email.toLowerCase().includes(filterValue)
    ).slice(0, 5)
  }

  public async invoicePreview (customer: Customer): Promise<void> {
    try {
      const response = await this._order
        .getInvoiceSample(customer._id).toPromise()

      const url = window.URL.createObjectURL(response.data)
      const anchor = document.createElement('a')

      anchor.href = url
      anchor.target = '_blank'
      anchor.click()
    } catch (error) {
      this.alert = new Alert({
        type: AlertType.DANGER,
        message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
      })
      this.alert.present()
      this.loading = false
    }
  }

  public async downloadCSV (customer: Customer): Promise<void> {
    const form: any = {}
    form.customer = customer._id
    const currentMonth = new Date()

    currentMonth.setUTCDate(1)
    currentMonth.setUTCHours(0, 0, 0, 0)

    const lastMonth = new Date(currentMonth.getTime())
    lastMonth.setMonth(lastMonth.getMonth() - 1)

    form.start = lastMonth
    form.end = currentMonth
    form.prechargeOnly = false

    await this._customer.exportCustomerCSV(customer, form)
  }
}
