import { Component, OnDestroy, ViewChild } from '@angular/core'
import { Operator } from 'src/app/classes/operator.class'
import { FormGroup, Validators, ValidationErrors, AbstractControl, FormControl } from '@angular/forms'
import { Subscription } from 'rxjs'
import { OperatorService } from 'src/app/services/operator.service'
import { TranslateService } from '@ngx-translate/core'
import { UtilityService } from '../../services/utility.service'
import { AuthService } from '../../services/auth.service'
import { Alert } from 'src/app/classes/alert.class'
import { AlertType } from 'src/app/enums/alert-type.enum'
import { FormCanDeactivate } from '../../guards/leave-page/form-can-deactivate'
import { ModalComponent } from 'src/app/components/modal/modal.component'

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
})
export class ProfileComponent extends FormCanDeactivate implements OnDestroy {

  @ViewChild(ModalComponent) leaveModal: ModalComponent

  public loading = false
  public showPwd = false
  private subscriptions: Subscription[] = []
  private usernames: String[] = []
  public alert: Alert
  public updateProfileForm: FormGroup = new FormGroup({
    _id: new FormControl(undefined),
    locale: new FormControl(undefined),
    authn: new FormGroup ({
      email: new FormControl('', [Validators.required, Validators.email]),
      _id: new FormControl(undefined)
    }),
    firstname: new FormControl('', [Validators.required]),
    lastname: new FormControl('', [Validators.required]),
    username: new FormControl(
      '',
      [Validators.required, this.checkUsernameAvailability()]
    ),
    genre: new FormControl(undefined, [Validators.required]),
    newsletter: new FormControl(false)
  })

  public updatePasswordForm: FormGroup = new FormGroup ({
    oldPassword: new FormControl(
      'placeholder',
      [Validators.required, Validators.minLength(5)]
    ),
    newPassword:
      new FormControl('', [Validators.required, Validators.minLength(5)]),
    passwordConfirm: new FormControl('', [Validators.required]),
  }, [
    this.matchPassword('newPassword', 'passwordConfirm'),
    this.samePassword('oldPassword', 'newPassword')
  ])

  constructor (
    private _translate: TranslateService,
    private _operator: OperatorService,
    public _utility: UtilityService,
    private _auth: AuthService
  ) {
    super()
    this.init()
  }

  ngOnDestroy () {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }

  private async init () {
    this.loading = true
    await this.getOperators()
    this.patchValueIntoForm()
    this.updatePasswordForm.controls['oldPassword'].disable()
  }

  public async saveData (): Promise<void> {
    this.loading = true
    try {
      const value = this.updateProfileForm.value
      const operator = new Operator({
        user: {
          ...value,
          genre: value.genre && value.genre.toUpperCase(),
        },
        username: value.username,
        locale: value.locale
      })

      const response = await this._operator.updateProfile(operator)
        .toPromise()

      if (response.invalid) {
        return this.openErrorAlert(
          `${this._translate.instant('ALERT.MESSAGE.INVALID')}`
          + ` ${this._translate.instant(response.message)}`)
      }
      this.alert = new Alert({
        type: AlertType.SUCCESS,
        message: this._translate.instant(`ALERT.MESSAGE.PROFILE_UPDATED`)
      })

      this.updateProfileForm.markAsPristine()
      this.loading = false
      return this.alert.present()
    } catch (error) {
      this.openErrorAlert(
        `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${this._translate.instant(error.statusText)}`)
    }
  }

  public async savePassword (): Promise<void> {
    try {
      const query = {
        oldPassword: this.updatePasswordForm.value.oldPassword,
        newPassword: this.updatePasswordForm.value.newPassword
      }
      const response = await this._auth.changePassword(
        query.oldPassword,
        query.newPassword
      ).toPromise()
      if (response.invalid || response.message !== 'OK') {
        return this.openErrorAlert(
          `${this._translate.instant('ALERT.MESSAGE.INVALID')}`
          + ` ${this._translate.instant(response.message)}`)
      }
      this.alert = new Alert({
        type: AlertType.SUCCESS,
        message: this._translate.instant(`ALERT.MESSAGE.PASSWORD_UPDATED`)
      })
      this.showPwd = false
      return this.alert.present()
    } catch (error) {
      this.openErrorAlert(
        `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${this._translate.instant(error.statusText)}`)
    }
  }

  private async getOperators () {
    try {
      const response = await this._operator.getOperators().toPromise()
      response.valid
        ? this.usernames = response.data.map(({ username }) => username)
        : this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.INVALID')}`
          + ` ${this._translate.instant(response.message)}`)
    } catch (error) {
      this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${error.statusText || error}`)
    }
  }

  private matchPassword (
    password: string,
    confirm: string
  ): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null =>
    (
      !control.value ||
      !control.value[password] ||
      !control.value[confirm] ||
      control.value[password] === control.value[confirm]
    )
        ? null
        : { matchPassword: true }
  }

  private samePassword (
    oldPassword: string,
    newPassword: string
  ): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null =>
    (
      !control.value ||
      !control.value[oldPassword] ||
      !control.value[newPassword] ||
      control.value[oldPassword] !== control.value[newPassword]
    )
        ? null
        : { samePassword: true }
  }

  private checkUsernameAvailability (
  ): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return (
        !control.value ||
        !this.usernames.includes(control.value)
      )
        ? null
        : { matchValues: true }
    }
  }

  public async patchValueIntoForm () {
    try {
      const response = await this._operator.getProfile().toPromise()
      if (response.valid) {
        const operator = response.data
        this.usernames = this.usernames.filter(u => u !== operator.username)

        this.updateProfileForm.patchValue({
          ...operator.user,
          username: operator.username,
          locale: operator.locale,
        })
        this.loading = false
      } else {
        this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.INVALID')}`
          + ` ${this._translate.instant('ERROR.OPERATOR_NOT_FOUND')}`)
      }
    } catch (error) {
      this.openErrorAlert(`${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')}`
        + ` ${error.statusText || error}`)
    }
  }

  public isFalse (value: boolean): boolean {
    return !value
  }

  private openErrorAlert (message: string): void {
    this.loading = false
    this.alert = new Alert({ type: AlertType.DANGER, message })
    return this.alert.present()
  }

  public togglePwd (reset: boolean) {
    if (!reset) {
      if (this.showPwd) {
        this.updatePasswordForm.reset()
        this.updatePasswordForm.controls['oldPassword'].disable()
        this.updatePasswordForm.controls.oldPassword.setValue('placeholder')
      } else {
        this.updatePasswordForm.controls['oldPassword'].enable()
        this.updatePasswordForm.controls.oldPassword.setValue('')
      }
      this.showPwd = !this.showPwd
    } else {
      this.showPwd = false
      this.updatePasswordForm.controls['oldPassword'].disable()
      this.updatePasswordForm.reset()
      this.updatePasswordForm.controls.oldPassword.setValue('placeholder')

    }
  }

  public pwdErrorAndTouched (): Boolean {
    if (this.showPwd) {
      const pF = this.updatePasswordForm
      const oldP = pF.controls['oldPassword'].touched
        && pF.controls['oldPassword'].invalid
      const newP = pF.controls['newPassword'].touched
        && (pF.controls['newPassword'].invalid || pF.hasError('samePassword'))
      const reqP = pF.controls['passwordConfirm'].touched
        && (
          pF.controls['passwordConfirm'].invalid ||
          pF.hasError('matchPassword')
        )
      return oldP || newP || reqP
    } else {
      return false
    }
  }

  public getModal (): ModalComponent {
    return this.leaveModal
  }

  public getForm () {
    return [this.updateProfileForm, this.updatePasswordForm]
  }

}
