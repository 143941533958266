import { Component, Inject, OnInit, ViewChild } from '@angular/core'
import { FormGroup, Validators, ValidationErrors, AbstractControl, FormControl } from '@angular/forms'
import { Alert } from 'src/app/classes/alert.class'
import { ModalComponent } from '../../../components/modal/modal.component'
import { Device } from 'src/app/classes/device.class'
import { Role } from 'src/app/classes/role.class'
import { Observable } from 'rxjs'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { CustomerService } from 'src/app/services/customer.service'
import { PosService } from 'src/app/services/pos.service'
import { StationService } from 'src/app/services/station.service'

export interface IOperator {
  email: string
  _id: string
}

@Component({
  selector: 'app-create-device',
  templateUrl: './create-device.component.html',
  styleUrls: ['./create-device.component.scss']
})
export class CreateDeviceComponent implements OnInit {

  @ViewChild(ModalComponent) leaveModal: ModalComponent

  public loading = true
  public alert: Alert
  public preview: string
  public plans: Role[]
  public operators: IOperator[] = []
  public filteredOperators: Observable<IOperator[]>
  public customers: any
  public stations: any
  public pos: any

  // tslint:disable-next-line: member-ordering
  public createDeviceForm: FormGroup = new FormGroup({
    _id: new FormControl(undefined),
    name: new FormControl('', [Validators.required]),
    password: new FormControl(
      '',
      [Validators.required, Validators.minLength(5)]
    ),
    passwordConfirm: new FormControl(
      '',
      [Validators.required, this.matchPassword('password')]
    ),
    customer: new FormControl(undefined),
    station: new FormControl(undefined),
    pos: new FormControl(undefined),
    active: new FormControl(true)
  })

  constructor (
    public dialogRef: MatDialogRef<CreateDeviceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _customer: CustomerService,
    private _station: StationService,
    private _pos: PosService
  ) { }

  async ngOnInit () {
    const customers = await this._customer.getAll().toPromise()
    this.customers = customers.data.data

    const station = await this._station.eloadedStation().toPromise()
    this.stations = station.data.data

    const pos = await this._pos.getAll().toPromise()
    this.pos = pos.data

    this.setUpdatePage()

    this.loading = false
  }

  async setUpdatePage (): Promise<void> {
    if (this.data) {
      const device: Device = new Device(this.data)
      this.createDeviceForm.get('name').disable()
      this.createDeviceForm.removeControl('password')
      this.createDeviceForm.removeControl('passwordConfirm')
      this.createDeviceForm.patchValue({
        ...device,
        customer: this.data.customer && this.data.customer._id,
        pos: this.data.pos && this.data.pos.id,
        station: this.data.station && this.data.station._id
      })
    }
  }

  get updateMode (): boolean {
    return !!this.data
  }

  private matchPassword (
    passwordControlName: string
  ): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null =>
      (
        !control.value ||
        !control.parent.get(passwordControlName).value ||
        control.parent.get(passwordControlName).value === control.value
      )
        ? null
        : { matchPassword: true }
  }

  public markFormGroupTouched (formGroup: FormGroup): void {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched()

      if (control.controls) {
        this.markFormGroupTouched(control)
      }
    })
  }

  public submit () {
    if (this.createDeviceForm.invalid) {
      return this.markFormGroupTouched(this.createDeviceForm)
    }

    this.loading = true
    this.dialogRef.close(this.createDeviceForm.value)
  }

  public cancel () {
    this.dialogRef.close()
  }
}
