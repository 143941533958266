import { Component, Inject, OnInit, ViewChild } from '@angular/core'
import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { Alert } from 'src/app/classes/alert.class'
import { AlertType } from 'src/app/enums/alert-type.enum'
import { CustomerPlan } from 'src/app/enums/customer-plan.enum'
import { FormCanDeactivate } from '../../../guards/leave-page/form-can-deactivate'
import { ModalComponent } from '../../../components/modal/modal.component'
import { RoleService } from 'src/app/services/role.service'
import { Customer } from 'src/app/classes/customer.class'
import { Role } from 'src/app/classes/role.class'
import { Operator } from 'src/app/classes/operator.class'
import { OperatorService } from 'src/app/services/operator.service'
import { ImageService } from 'src/app/services/image.service'
import { SafeUrl } from '@angular/platform-browser'
import { Observable } from 'rxjs'
import { filter, map, startWith } from 'rxjs/operators'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { FileService } from 'src/app/services/file.service'

export interface IOperator {
  email: string
  _id: string
}

@Component({
  selector: 'app-create-customer',
  templateUrl: './create-customer.component.html',
  styleUrls: ['./create-customer.component.scss']
})
export class CreateCustomerComponent
  extends FormCanDeactivate
  implements OnInit {

  @ViewChild(ModalComponent) leaveModal: ModalComponent

  public loading = true
  public alert: Alert

  public preview: string
  public plans: Role[]
  public operators: IOperator[] = []
  public filteredOperators: Observable<IOperator[]>
  public allRoles: Array<Role> = []

  validateRootOperator: ValidatorFn = (
    control: AbstractControl
  ): ValidationErrors | null => {
    const isValidRootOperatorSelected = this.operators
      .filter((operator) => operator._id === control.value._id)

    if (!!isValidRootOperatorSelected.length) {
      return null
    } else {
      return { invalidOperator: true }
    }
  }

  // tslint:disable-next-line: member-ordering
  public rootOperatorFormControl = new FormControl(null, [
    Validators.required,
    this.validateRootOperator
  ])
  // tslint:disable-next-line: member-ordering
  public createCustomerForm: FormGroup = new FormGroup({
    _id: new FormControl(undefined),
    name: new FormControl('', [Validators.required]),
    plan: new FormControl(null, [Validators.required]),
    rootOperator: this.rootOperatorFormControl,
    privileges: new FormArray([]),
    invoicing: new FormGroup({
      logo: new FormControl(undefined, [Validators.required]),
      header: new FormControl('', [Validators.required])
    }),
  })

  // tslint:disable-next-line: member-ordering
  public invoicingLogo: string

  constructor (
    public dialogRef: MatDialogRef<CreateCustomerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _translate: TranslateService,
    private _file: FileService,
    private _role: RoleService,
    private _operator: OperatorService,
    private _image: ImageService
  ) {
    super()
  }

  get updateMode (): boolean {
    return !!this.data
  }

  displayFn (operator: IOperator): string {
    return operator && operator.email ? operator.email : ''
  }

  async setUpdatePage (): Promise<void> {
    if (!this.data) {
      return
    }
    this.preview = this.data.invoicing.logo

    const customer: Customer = new Customer(this.data)
    this.createCustomerForm.patchValue({
      ...customer,
      plan: customer.plan._id,
      rootOperator: customer.rootOperator &&
        this.getRootOperator(customer.rootOperator._id),
      invoicing: {
        logo: this._file.base64toFile(
          this.data.invoicing.logo,
          'image/jpeg',
          'oldImage.jpeg'
        ),
        header: customer.invoicing.header &&
          customer.invoicing.header.join('\n')
      }
    })
  }

  private getRootOperator (id: string) {
    const operator = this.operators.find(operator => operator._id === id)
    return {
      _id: operator._id,
      email: operator.email
    }
  }

  public markFormGroupTouched (formGroup: FormGroup): void {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched()

      if (control.controls) {
        this.markFormGroupTouched(control)
      }
    })
  }

  public submit () {
    if (this.createCustomerForm.invalid) {
      return this.markFormGroupTouched(this.createCustomerForm)
    }
    this.loading = true
    this.dialogRef.close({
      ...this.createCustomerForm.value,
      rootOperator: this.createCustomerForm.value.rootOperator,
      plan: { _id: this.createCustomerForm.value.plan },
      privileges: this.createCustomerForm.value.privileges
        .map(el => ({ _id: el })),
      invoicing: {
        ...this.createCustomerForm.value.invoicing,
        header: this.createCustomerForm.value.invoicing.header.split('\n')
      }
    })
  }

  async ngOnInit () {
    await this.getPlans()
    try {
      const result = await this._operator
        .searchOperator(new Operator({})).toPromise()
      if (result.invalid) {
        this.alert = new Alert({
          type: AlertType.DANGER,
          message: `${this._translate.instant('ALERT.MESSAGE.INVALID')} ${result.message}`
        })
        this.alert.present()
        return
      }
      this.operators = result.data
        .map(o => ({ email: o.user.authn.email, _id: o._id }))

    } catch (error) {
      this.alert = new Alert({
        type: AlertType.DANGER,
        message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error}`
      })
      this.alert.present()
    }

    this.setUpdatePage()

    this.filteredOperators = this.rootOperatorFormControl.valueChanges
      .pipe(
        startWith(''),
        filter(value => !!value),
        map(value => typeof value === 'string' ? value : value.email),
        map((value: string) => value
          ? this._filter(value)
          : this.operators.slice()
        )
      )

    this.loading = false
  }

  private async getPlans () {
    await this.getRoles()
    this.plans = (this.allRoles || [])
      .filter(r =>
        r.name.startsWith('CUSTOMER.') &&
        CustomerPlan[r.name.split('.')[1]] !== undefined
      )
      .sort((a, b) =>
        CustomerPlan[a.name.split('.')[1]] - CustomerPlan[b.name.split('.')[1]]
      )
  }

  public async getRoles (page?: number): Promise<boolean> {
    try {
      const response =
      await this._role.searchRole({page}).toPromise()
      if (response.invalid) {
        this.alert = new Alert({
          type: AlertType.DANGER,
          message: `${this._translate.instant('ALERT.MESSAGE.INVALID')} ${response.message}`
        })
        this.alert.present()
        this.loading = false
        return
      }

      if (!page) {
        this.allRoles = response.data.data.sort((r1, r2) =>
          r1.name.localeCompare(r2.name))
      } else {
        this.allRoles = this.allRoles.concat(response.data.data)
        this.allRoles.sort((r1, r2) =>
          r1.name.localeCompare(r2.name))
      }
      if (response.data.hasNext) {
        this.getRoles((page || 0) + 1)
      }
    } catch (error) {
      this.alert = new Alert({
        type: AlertType.DANGER,
        message: `${this._translate.instant('ALERT.MESSAGE.SYSTEM_ERROR')} ${error.statusText}`
      })
      this.alert.present()
      return false
    }
  }

  private _filter (input: string): IOperator[] {
    const filterValue = input.toLowerCase()

    return this.operators.filter((operator: IOperator) =>
      operator.email.toLowerCase().includes(filterValue)
    )
  }

  public handleInputChange (event) {
    const file = (event.dataTransfer)
      ? event.dataTransfer.files[0]
      : event.target.files[0]

    if (!file) {
      this.createCustomerForm.patchValue({ invoicing: { logo: undefined } })
      this.preview = undefined

      return
    }

    const pattern = /^image\//

    if (!file.type.match(pattern)) {
      document.getElementById('image')['value'] = ''
      alert(this._translate.instant('ERROR.IMAGE_INVALID_FORMAT'))

      return
    }

    const reader = new FileReader()
    reader.onload = this._handleReaderLoaded.bind(this)
    reader.readAsDataURL(file)
    this.createCustomerForm.patchValue({ invoicing: { logo: file } })
    this.createCustomerForm.markAsDirty()
  }

  private _handleReaderLoaded (event: any) {
    const { result } = event.target
    this.preview = (result as string).split(',')[1]
  }

  public getModal (): ModalComponent {
    return this.leaveModal
  }

  public getForm () {
    return [this.createCustomerForm]
  }

  public base64ToUrl (base64: string): SafeUrl {
    return this._image.base64ToUrl(base64)
  }

  public cancel () {
    this.dialogRef.close()
  }
}
